import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MarkdownLayout.jsx";
import { Helmet } from "react-helmet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet title="Software" mdxType="Helmet" />
    <h1>{`Software`}</h1>
    <p>{`Software that fits the principles of Pleasant Design`}</p>
    <p><em parentName="p">{`Coming soon!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      